import axios from 'axios';

export async function listTransactions() {

  var list = null

  var post_args = {
    post_type: 'transactions',
    post_status: 'published',
  }

  var meta_args = {


  }

  var params = {
    user: localStorage.getItem("user_id"),
    post_args,
    meta_args
  }

  /* var formdata = new FormData()
  formdata.append("user", localStorage.getItem("user_id"))
  formdata.append("post_args", post_args)
  formdata.append("meta_args", meta_args) */

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}` } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/get.posts.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      list = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return list

}






export async function getLastBalance() {

  var list = null

  var post_args = {
    post_type: 'transactions',
    post_status: 'published',
    order_by: 'id DESC LIMIT 1'
  }

  var meta_args = {


  }

  var params = {
    user: localStorage.getItem("user_id"),
    post_args,
    meta_args
  }
  
  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}` } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/get.posts.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      list = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return list

}











export async function getTransaction(id) {

  var data = null

  var post_args = {
    id,
    post_type: 'transactions'
  }

  var params = {
    user: localStorage.getItem("user_id"),
    post_args: post_args
  }

  /* var formdata = new FormData()
  formdata.append("source", "webapp")
  formdata.append("user", localStorage.getItem("user_id"))
  formdata.append("post_args", post_args) */

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }


  console.log('getTransaction', params)

  await axios
    .post(
      "https://portinari.nodespace.io/api/get.posts.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);

      if (response.data.length > 0) {
        data = response.data[0]
      }

    })
    .catch((error) => {
      console.log("error", error)
    });

  return data
}

export async function updateTransaction(id, postData, metaData) {

  var res = null;

  //postData['post_type'] = 'transactions'

  var params = {
    user: localStorage.getItem("user_id"),
    id,
    post_data: postData,
    post_meta: metaData
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  console.log('paramsss', params);

  await axios
    .post(
      "https://portinari.nodespace.io/api/update.post.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}

export async function createTransaction(postData, metaData, notify) {

  var res = null;

  postData['post_type'] = 'transactions'

  var params = {
    user: localStorage.getItem("user_id"),
    post_data: postData,
    post_meta: metaData,
    notification: (notify === true ? 'new-transaction' : '')
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/add.post.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);
      res = response.data

    })
    .catch((error) => {
      console.log("error", error)
    });

  return res


}


export async function deleteMedia(id, media) {

  var res = null;

  //postData['post_type'] = 'transactions'

  var params = {
    user: localStorage.getItem("user_id"),
    id,
    kind: 'posts',
    post_type: 'transactions',
    filepath: media
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/delete.file.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}






export async function deleteTransaction(post_data) {

  var res = null;

  //postData['post_type'] = 'transactions'

  var params = {
    user: localStorage.getItem("user_id"),
    post_data
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/delete.post.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}





