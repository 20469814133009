import axios from 'axios';

export async function listUsers() {

  var list = null

  var user_args = {
    
  }

  var meta_args = {


  }

  var formdata = new FormData()
  formdata.append("user", localStorage.getItem("user_id"))
  formdata.append("user_args", user_args)
  formdata.append("meta_args", meta_args)

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}` } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/get.users.php",
      formdata,
      config
    )
    .then((response) => {
      list = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return list

}
















export async function getUser(id) {

  var data = null

  var user_args = {
    id
  }

  var params = {
    user: localStorage.getItem("user_id"),
    user_args: user_args
  }

 
  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }


  await axios
    .post(
      "https://portinari.nodespace.io/api/get.users.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);

      if (response.data.length > 0) {
        data = response.data[0]
      }

    })
    .catch((error) => {
      console.log("error", error)
    });

  return data
}

export async function updateUser(id, userData, userMeta) {

  var res = null;

  var params = {
    user: localStorage.getItem("user_id"),
    id,
    user_data: userData,
    user_meta: userMeta
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/update.user.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}

export async function createUser(userData, userMeta) {

  var res = null;

  var params = {
    user: localStorage.getItem("user_id"),
    user_data: userData,
    user_meta: userMeta
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/add.user.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);
      res = response.data

    })
    .catch((error) => {
      console.log("error", error)
    });

  return res


}


export async function deleteUser(user_data) {

  var res = null;

  //postData['post_type'] = 'transactions'

  var params = {
    user: localStorage.getItem("user_id"),
    user_data
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://portinari.nodespace.io/api/delete.user.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      console.log('response', response);
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}





