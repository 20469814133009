import Vue from 'vue'
import Router from "vue-router";

import Body from '../components/body'
import Auth from "../components/auth";
import Main from "../components/main";

import P from "../pages/p";

import Login from "../pages/login";
import Dashboard from '../pages/dashboard'

import ListTransactions from "../pages/transactions/list-transactions"


import ListUsers from "../pages/users/list-users"
import EditUser from "../pages/users/edit-user"



// component

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'default' } },
  {
    path: '/dashboard',
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'default',
        component: Dashboard,
        meta: {
          title: 'Dashboard | CRM - Colégio Portinari',
        }
      },
    ]
  },
  {
    path: '/profile',
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'profile',
        component: EditUser,
        meta: {
          title: 'Perfil | CRM - Colégio Portinari',
        }
      },
    ]
  },
  {
    path: "/transactions",
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "list",
        name: "transactions",
        component: ListTransactions,
        meta: {
          title: "Embarcações | CRM - Colégio Portinari",
        },
      }
    ],
  },
  {
    path: "/users",
    component: Body,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "list",
        name: "users",
        component: ListUsers,
        meta: {
          title: "Usuários | CRM - Colégio Portinari",
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
      {
        path: "create",
        name: "create",
        component: EditUser,
        meta: {
          title: "Criar Usuário | CRM - Colégio Portinari",
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
      {
        path: ":id",
        name: "edit",
        component: EditUser,
        meta: {
          title: "Editar Usuário | CRM - Colégio Portinari",
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: '/p',
    component: Main,
    children: [
      {
        path: ":id/:post_name",
        name: "P",
        component: P,
        meta: {
          title: "CRM - Colégio Portinari",
        },
      }
    ],
  },
  {
    path: '/',
    redirect: 'login',
    component: Auth,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          title: "Login | CRM - Colégio Portinari",
        },
      }
    ],
  }];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

import axios from 'axios';

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {


    var params = {
      user: localStorage.getItem("user_id"),
    }
    var token = localStorage.getItem("user_jwt")
    var config = { headers: { Authorization: `Bearer ${token}` } }


    var auth = await axios
      .post(
        "https://portinari.nodespace.io/api/auth.check.php?source=webapp",
        params,
        config
      )
      .then((response) => {
        return response.data === 'Ok' ? true : false
      })
      .catch((error) => {
        return false
      });

    if (to.matched.some(record => record.meta.requiresAdmin)) {
      console.log('requireADMIN', localStorage.getItem('user_role'));
      if (!auth || localStorage.getItem('user_jwt') == null || localStorage.getItem('user_role') !== 'administrator') {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    }
    else {
      console.log('NOT requireADMIN', localStorage.getItem('user_role'));
      if (!auth || localStorage.getItem('user_jwt') == null) {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
