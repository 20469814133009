<template>
  <div>
    <Breadcrumbs :main="(this.$route.name === 'profile' ? '' : 'Usuários')" :title="(this.$route.name === 'profile' ? 'Perfil' : 'Usuário')" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col-sm-2">
                    <div class="form-group mb-0" v-if="this.$route.name != 'profile'">
                      <div class="media">
                        <label class="col-form-label m-r-10">Status</label>
                        <div class="media-body text-right icon-state switch-outline">
                          <label class="switch">
                            <input type="checkbox" name="user_status" id="user_status" value="active"
                              :checked="userData['user_status'] === 'active'"
                              @change="() => { userData['user_status'] = userData['user_status'] === 'active' ? 'inactive' : 'active' }"><span
                              class="switch-state bg-success"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-8">

                  </div>
                  <div class="col-sm-2 text-right">
                    <div class="form-group mb-0">

                      <button type="button" class="btn btn-primary my-1" @click="saveData">{{ this.userID ?
                          "Salvar alterações" :
                          "Cadastrar"
                      }}</button>


                    </div>
                  </div>

                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->


    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-sm-12">
          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Dados</h6>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Nome</label>
                            <input class="form-control" type="text" placeholder="Nome" name="user_name"
                              v-model="userData['user_name']">
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Email</label>
                            <input class="form-control" type="text" placeholder="Email" name="user_email"
                              v-model="userData['user_email']">
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Telefone</label>
                            <input class="form-control" type="text" placeholder="Telefone" name="telefone"
                              v-model="userMeta['telefone']">
                          </div>
                        </div>

                      </div>




                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->



          <!-- Container-fluid starts-->
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <px-card>
                  <div slot="with-padding">
                    <div class="form theme-form">

                      <div class="row">
                        <div class="col-sm-12 mb-4">
                          <h6 class="sub-title">Acesso</h6>
                        </div>
                      </div>





                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Usuário de Acesso</label>
                            <input class="form-control" type="text" placeholder="Usuário" name="user_login" :readonly="userRole !== 'administrator'"
                              v-model="userData['user_login']">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label>Senha <small v-if="this.userID">(Deixe em branco para manter a senha
                                atual)</small></label>
                            <input class="form-control" type="text" placeholder="Senha" name="user_hash"
                              v-model="password">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group" v-if="userRole === 'administrator'" >
                            <label>Função</label>
                            <select class="form-control" v-model="userData['user_role']">
                              <option value="">-</option>
                              <option value="administrator">Administrador</option>
                              <option value="employee">Equipe</option>
                            </select>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </px-card>
              </div>
            </div>
          </div>
          <!-- Container-fluid Ends-->


        </div>
      </div>
    </div>



  </div>
</template>
<script>

var SHA256 = require("crypto-js/sha256");


import { getUser, updateUser, createUser } from '../../data/users'


export default {
  components: {

  },
  data() {
    return {

      userID: this.$route.params.id ? this.$route.params.id : (this.$route.name === 'profile' ? localStorage.getItem("user_id") : null),
      userRole: localStorage.getItem('user_role'),

      userData: {},
      userMeta: {},
      password: ''


    };
  },
  mounted() {
    if (this.userID) {
      this.loader = this.$loading.show()
      this.loaded = false
      this.loadData()
    }
  },
  methods: {
    async loadData() {


      let user = await getUser(this.userID)

      this.userData = {
        id: user.id,
        user_login: user.user_login,
        user_email: user.user_email,
        user_name: user.user_name,
        user_role: user.user_role,
        user_status: user.user_status
      }
      this.userMeta = user.user_meta

      console.log('user', this.userData)

      this.loaded = true
      setTimeout(() => {
        this.loader.hide()
      }, 500)
    },

    async saveData() {

      if (this.userData['user_name'].trim() && this.userData['user_email'].trim() && this.userData['user_login'].trim() && this.userData['user_role'].trim()) {

        if ((this.userID && (this.password.trim().length === 0 || this.password.trim().length >= 8)) || (!this.userID && this.password.trim().length >= 8)) {
          this.loader = this.$loading.show()
          this.loaded = false
          //this.loadData()

          if (this.password.trim() != "") {
            let hash = SHA256(this.password).toString();
            this.userData['user_hash'] = hash;
            this.password = '';
          }

          this.userData['user_status'] = this.userData['user_status'] === 'active' ? 'active' : 'inactive';

          console.log('saveData', this.userData, this.userMeta)

          if (this.userID) {
            var res = await updateUser(this.userID, this.userData, this.userMeta);
            if (res > 0) {
              this.$toasted.show(' Salvo com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 });
            }
          }
          else {
            var res = await createUser(this.userData, this.userMeta);
            if (res > 0) {
              this.$toasted.show(' Salvo com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 });
              this.$router.push({ path: `/users/${res}` })
            }
          }

          console.log('saveData-res', res)
          setTimeout(() => {
            this.loader.hide()
          }, 500)
        }
        else {
          this.$toasted.show(' Por favor, defina uma senha com pelo menos 8 caracteres ', { theme: 'outline', position: 'top-right', type: 'danger', icon: 'info', duration: 5000 });
        }

      }
      else {

        this.$toasted.show(' Por favor, preencha os campos corretamente ', { theme: 'outline', position: 'top-right', type: 'danger', icon: 'info', duration: 5000 });

      }





    },
    onKeywordSelect() {
      // console.log('dataItem.keywords', this.dataItem.keywords)
    },
    addTag(newTag) {
      this.keywords.push(newTag)
      this.selectedKeywords.push(newTag)
    },
  }
};
</script>



































































































<style src="vue-multiselect/dist/vue-multiselect.min.css" />


