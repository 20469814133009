<template>
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <router-link to="/">
          <img class="img-fluid" src="../assets/images/logo-portinari.png" alt style="height: 40px;" />
        </router-link>
      </div>
      <div class="toggle-sidebar" @click="toggle_sidebar">
        <feather class="status_toggle middle sidebar-toggle" type="sliders" id="sidebar-toggle"></feather>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0">
      <ul class="horizontal-menu">
        <li class="mega-menu outside">

        </li>
        <li class="level-menu outside">

        </li>
      </ul>
    </div>
    <div class="nav-right col-8 pull-right right-header p-0">
      <ul class="nav-menus">

        <li class="onhover-dropdown">
          <div class="notification-box">
            <feather type="bell"></feather><span class="badge badge-pill badge-secondary"
              v-if="notifications.length > 0">{{ notifications.length }}</span>
          </div>
          <ul class="notification-dropdown onhover-show-div">
            <li>
              <feather type="bell"></feather>
              <h6 class="f-18 mb-0">Notificações</h6>
            </li>
            <li v-if="notifications.length < 1">
              <p><small>
                  Nenhuma notificação
                </small>
              </p>
            </li>
            <li v-for="notification in this.notifications" :key="'notification-' + notification.id"
              @click="openNotification(notification.post_meta['ref_type'], notification.post_meta['ref'])">
              <p>
                <strong>
                  {{ notification.post_meta['subject'] }}
                </strong>
              </p>
              <p>
                <small>
                  {{ formatDate(notification.post_date) }}
                </small>
              </p>
              <p style="text-align: left;"><small>
                  {{ notification.post_meta['message'] }}
                </small>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <div class="mode">
            <i class="fa fa-moon-o" v-show="mixLayout == 'dark-sidebar'" @click="customizeMixLayout('dark-only')"></i>
            <i class="fa fa-lightbulb-o" v-show="mixLayout == 'dark-only'"
              @click="customizeMixLayout('dark-sidebar')"></i>
          </div>
        </li>


        <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" @click="toggle_fullscreen()">
            <feather type="maximize"></feather>
          </a>
        </li>
        <li class="profile-nav onhover-dropdown p-0 mr-0">
          <div class="media profile-media">
            <!-- <img class="b-r-10" src="../assets/images/dashboard/profile.jpg" alt="" /> -->
            <i class="fa fa-user my-2 ml-2" style="font-size: 18px;"></i>
            <div class="media-body">
              <span>{{ username }}</span>
              <p class="mb-0 font-roboto">
                {{ userrole }} <i class="middle fa fa-angle-down"></i>
              </p>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div">
            <li>
              <router-link to="/profile">
                <feather type="user"></feather><span>Perfil </span>
              </router-link>
            </li>
            <li>
              <a @click="logout()">
                <feather type="log-in"></feather><span>Sair</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

  </div>
</template>
<script>
var body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";
import Bookmark from "./bookmark";
import { listNotifications } from '../data/notifications'
import moment from 'moment'


export default {
  name: "Search",
  data() {
    return {
      terms: "",
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      close_sidebar_var: false,
      clicked: false,
      mobile_toggle: false,
      mobile_search: false,
      openbonusUI: false,
      openLevelmenu: false,
      openlanguage: false,
      mobile_accordian: false,
      mixLayout: "dark-sidebar",
      username: localStorage.getItem("user_name"),
      userrole: localStorage.getItem("user_role") === 'administrator' ? 'Administrador' : 'Equipe',
      notifications: []
    };
  },
  components: {
    Bookmark,
  },
  async mounted() {
    console.log('-----------MOUNTED HEADER---------');

    await this.loadNotifications()

  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
    }),
  },
  methods: {
    async loadNotifications() {
      this.notifications = await listNotifications()
      console.log('this.notifications', this.notifications);
    },
    async openNotification(ref_type, ref) {

      this.$router.push({ path: `/${ref_type}/${ref}` })

    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_role");
      localStorage.removeItem("user_jwt");
      //window.location.reload(true);
      this.$router.replace("/login")
    },
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setBonusNavActive", item);
    },
    openlangpicker() {
      this.openlanguage = !this.openlanguage;
    },
    openlevelmenu() {
      this.openLevelmenu = !this.openLevelmenu;
    },
    openmegamenu() {
      this.openbonusUI = !this.openbonusUI;
    },
    closeBonusUI() {
      this.openbonusUI = false;
    },
    search_open() {
      this.searchOpen = true;
    },
    search_close() {
      this.searchOpen = false;
    },
    searchterm: function () {
      this.$store.dispatch("menu/searchTerm", this.terms);
    },
    changeLocale(locale) {
      this.setLang(locale);
    },
    mobileaccordian() {
      this.mobile_accordian = !this.mobile_accordian;
    },
    addFix() {
      body.classList.add("offcanvas");
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove("offcanvas");
      this.searchResult = false;
      this.terms = "";
    },
    toggle_fullscreen() {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    customizeMixLayout(val) {
      this.mixLayout = val;
      this.$store.dispatch("layout/setLayout", val);
    },
    formatDate(value) {
      try {
        return moment(value).format('DD/MM/YYYY @ H:mm')
      } catch (e) {
        return null
      }
    },
  },
  watch: {
    "$i18n.locale"(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
    menuItems: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.menuItems.length) this.searchResultEmpty = true;
      else this.searchResultEmpty = false;
    },
  },
};
</script>


