<template>
  <div>
    <Breadcrumbs title="Transações" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col-sm-3 pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center w-100">
                        Filtrar:
                        <b-form-input id='filter-input' type="search" placeholder="Digite para procurar..."
                          class="form-control form-control-sm ml-2" @keyup="debounceInput" @click="debounceInput">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-2 pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center w-100">
                        Data:
                        <datepicker v-model="filterDate" @input="dtFilterChanged" @blur="dtFilterChanged"
                          :typeable="false" :use-utc="false"
                          input-class="datepicker-here form-control orm-control-sm ml-2 digits"
                          :format="dtFilterFormat"></datepicker>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3 px-5">
                    <div class="form-group mb-0 mt-0 px-5">
                      Valor: <vue-slider v-model="fvalues" :min="min" :max="max" :enable-cross="false"
                        :tooltip="'always'" :tooltipPlacement="['left', 'right']"
                        :tooltip-formatter="(val) => 'R$ ' + (val ? val : 0)" :useKeyboard="false"
                        @drag-end="amountFilterChanged">
                      </vue-slider>
                    </div>
                  </div>

                  <div class="col-sm-2 text-right  pt-3 px-5">
                    <div class="form-group mb-0">
                      <button @click="resetFilter" class="form-control form-control-sm">Reset</button>
                    </div>
                  </div>

                  <div class="col-sm-2 text-right  pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center">
                        Exibir&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="savePerPage">
                        </b-form-select>&nbsp;registros
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">







                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group mb-0">

                      <div class="table-responsive mb-0">
                        <b-table ref="tbl" class="table-striped mb-0" style="font-size: 12px" :items="dataList"
                          :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-compare="sortCompare" :filter="filter"
                          :filter-included-fields="filterOn" @filtered="onFiltered" @sort-changed="onSort">




                          <template v-slot:cell(description)="{ item }">
                            <span v-if="item.post_meta['description']">{{ item.post_meta['description'] }}</span>
                          </template>

                          <template v-slot:cell(reference)="{ item }">
                            <span v-if="item.post_meta['reference']">{{ item.post_meta['reference'] }}</span>
                          </template>

                          <template v-slot:cell(kind)="{ item }">

                            <span v-if="item.post_meta['kind'] === 'in'" class="text-success"><i class="fa fa-plus"></i>
                              Entrada</span>

                            <span v-if="item.post_meta['kind'] === 'out'" class="text-danger"><i
                                class="fa fa-minus"></i>
                              Saída</span>
                          </template>

                          <template v-slot:cell(amount)="{ item }">
                            <span v-if="item.post_meta['amount']"
                              :class="item.post_meta['kind'] === 'in' ? 'text-success' : 'text-danger'">{{
                                  parseFloat(item.post_meta['amount']).toFixed(2)
                              }}</span>
                          </template>



                          <template v-slot:cell(balance)="{ item }">
                            <span v-if="item.post_meta['balance']">{{ parseFloat(item.post_meta['balance']).toFixed(2)
                            }}</span>
                          </template>

                          <template v-slot:cell(method)="{ item }">
                            <span v-if="item.post_meta['method']">{{ item.post_meta['method']
                            }}</span>
                          </template>


                          <template v-slot:cell(actions)="{ item }">

                            <b-link class="action-delete font-weight-bold text-danger" @click="deleteData(item)"
                              v-if="userID == item.post_author || userRole == 'administrator'">
                              <i class="fa fa-trash"></i>
                            </b-link>
                          </template>


                          <template v-slot:cell(serialized)="{ item }">
                            <span>{{ JSON.stringify(item) }}</span>
                          </template>


                        </b-table>
                      </div>


                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-sm-12">
                    <div class="form-group mb-0">



                      <div class="dataLists_paginate paging_simple_numbers">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                        </ul>
                      </div>



                    </div>
                  </div>
                </div>


                <div class="row mt-5">

                  <div class="col-sm-3">


                  </div>
                  <div class="col-sm-2 text-center">
                    Entradas:
                    <h5>R$ {{ incomes }}</h5>

                  </div>
                  <div class="col-sm-2 text-center">
                    Saídas:
                    <h5>R$ {{ expenses }}</h5>

                  </div>

                  <div class="col-sm-2 text-center">
                    Saldo:
                    <h5>R$ {{ balance }}</h5>

                  </div>

                  <div class="col-sm-3">


                  </div>
                </div>







              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->





    <!-- Container-fluid starts-->
    <div class="container-fluid" style="margin-bottom: 100px;" id="create">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">

                <div class="row">
                  <div class="col-sm-12">
                    <h6>Adicionar nova transação</h6>
                  </div>
                </div>

                <div class="row">



                  <div class="col-sm-3 text-left  pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center">

                        Descrição *</label><br>
                      <b-form-input v-model="newDescription" type="text" placeholder="Digite uma descrição..."
                        class="form-control form-control-sm w-100">
                      </b-form-input>


                    </div>
                  </div>

                  <div class="col-sm-3 text-left  pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center">

                        Referência</label><br>
                      <b-form-input v-model="newReference" type="text" placeholder="Digite uma referência..."
                        class="form-control form-control-sm w-100">
                      </b-form-input>


                    </div>
                  </div>


                  <div class="col-sm-2 text-left  pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center">
                        Tipo *</label><br>
                      <b-form-select v-model="newKind" class="form-control form-control-sm">
                        <option></option>
                        <option value='in'>Entrada</option>
                        <option value='out'>Saída</option>
                      </b-form-select>

                    </div>
                  </div>

                  <div class="col-sm-2 text-left  pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center">

                        Valor R$ *</label><br>
                      <b-form-input v-model="newAmount" type="number" placeholder="Valor"
                        class="form-control form-control-sm">
                      </b-form-input>


                    </div>
                  </div>

                  <div class="col-sm-2 text-left  pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center">

                        Forma de Pagamento</label><br>
                      <b-form-input v-model="newMethod" type="text" placeholder="Forma de pagamento..."
                        class="form-control form-control-sm">
                      </b-form-input>


                    </div>
                  </div>
                </div>


                <div class="row py-4">
                  <div class="col-sm-12">
                    <button type="button" class="btn btn-primary w-100" @click="saveData">Inserir</button>

                  </div>
                </div>



              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->




  </div>
</template>







<script>
import Vue from 'vue'
import moment from 'moment'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { listTransactions, deleteTransaction, createTransaction, getLastBalance } from '../../data/transactions'

import _ from 'lodash'

import Datepicker from 'vuejs-datepicker';

/**
 * Advanced table component
 */
export default {
  components: { VueSlider, Datepicker },
  data() {
    return {

      incomes: 0,
      expenses: 0,
      balance: 0,

      newDescription: '',
      newReference: '',
      newKind: '',
      newAmount: '',
      newMethod: '',

      dtFilterFormat: 'dd/MM/yyyy',
      userID: localStorage.getItem("user_id"),
      userRole: localStorage.getItem('user_role'),

      loaded: false,
      dataList: [],
      fullDataList: [],
      title: 'Transações',
      items: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'Transações',
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: Vue.$cookies.get('perPageTransactions') || 50,
      pageOptions: [50, 100, 200],
      filter: '',
      filterDate: '',
      filterOn: ['description', 'reference', 'kind', 'amount', 'method', 'balance', 'serialized'],
      sortBy: Vue.$cookies.get('sortByTransactions') || 'post_date',
      sortDesc: Vue.$cookies.get('sortDescTransactions') === 'true' ? true : false,
      fields: [
        { key: 'id', label: 'ID', sortable: false, thClass: 'd-none', tdClass: 'd-none' },
        { key: 'post_date', label: 'Data', sortable: true, formatter: this.formatDate, filterByFormatted: true },
        { key: 'description', label: 'Descrição', sortable: true },
        { key: 'reference', label: 'Referência', sortable: true },
        { key: 'kind', label: 'Entrada / Saída', sortable: true, filterByFormatted: true, formatter: this.formatKind, },
        { key: 'amount', label: 'Valor R$', sortable: true },
        { key: 'method', label: 'Forma de Pagamento', sortable: true },
        { key: 'balance', label: 'Saldo R$', sortable: true },
        { key: 'actions', label: 'Ações' },
        { key: 'serialized', label: 'Serialized', sortable: true, formatter: this.formatSerialize, filterByFormatted: true, thClass: 'd-none', tdClass: 'd-none' },
      ],
      fvalues: [0, 0],
      min: 0,
      max: 0
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows || this.dataList.length
    },
  },
  mounted() {

    /* const tbl = this.$refs.tbl;
    tbl.$on('filtered', filteredItems => {
      console.log('filtering TABLEEEE', filteredItems)
      this.filteredDataTable = filteredItems
      this.calcTotals()
    }); */

    // Set the initial number of items
    this.totalRows = this.dataList.length
    this.loader = this.$loading.show()
    this.loaded = false
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.fullDataList = await listTransactions()


      this.dataList = [...this.fullDataList]

      console.log('this.fullDataList', this.fullDataList);

      this.max = Math.max.apply(
        Math,
        this.dataList.map(function (o) {
          return o.post_meta && o.post_meta['amount'] ? parseFloat(o.post_meta['amount']) : 0
        })
      )
      this.fvalues = [0, (this.max != "-Infinity" ? this.max : 0)]

      this.calcTotals(this.dataList);

      this.loaded = true
      setTimeout(() => {
        this.loader.hide()
      }, 500)
    },
    async deleteData(item) {
      // console.log('delete id', item.id, this.dataList)
      if (confirm('Tem certeza de que deseja excluir ' + item.post_title + '?') === true) {
        if ((await deleteTransaction(item)) > 0) {
          let idx = this.dataList.indexOf(item)
          this.dataList.splice(idx, 1)
          this.calcTotals(this.dataList)
        }
      }
    },
    normalizeString(str) {
      str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      str = str.replace(/[^a-z0-9/]/gi, ' ')
      return str
    },
    formatSerialize(value, column, data) {
      var str = JSON.stringify(data)
      return this.normalizeString(str)

    },
    formatKind(value, column, data) {

      var r = data.post_meta['kind'] === 'in' ? "Entrada" : "Saída"
      var str = JSON.stringify(r)
      return this.normalizeString(str)

    },
    formatStatus(value) {

      switch (value) {
        case 'published': return 'Ativado';
        case 'pending': return 'Pendente';
        case 'inactive': return 'Desativado';
      }

    },
    formatDate(value) {

      console.log('value', value)
      try {
        return moment(value).format('DD/MM/YY @ H:mm')
        //return moment(value).format('DD/MM/YY')
      } catch (e) {
        return null
      }
    },
    sortCompare(a, b, key) {
      if (key === 'post_date') {
        // Assuming the date field is a `Date` object, subtraction
        // works on the date serial number (epoch value)
        return a[key] - b[key]
      } else {
        // Let b-table handle sorting other fields (other than `date` field)
        return false
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1

      console.log('filtering TABLEEEE', filteredItems)
      this.calcTotals(filteredItems)
    },
    debounceInput: _.debounce(function (e) {
      var val = this.normalizeString(e.target.value)
      this.filter = val
      console.log('filter', this.filter)
      
    }, 250),
    changeFilterDate(val) {
      this.filterDate = val
    },
    savePerPage(e) {
      console.log(e)
      Vue.$cookies.set('perPageTransactions', e)
    },
    onSort(e) {
      Vue.$cookies.set('sortByTransactions', e.sortBy)
      Vue.$cookies.set('sortDescTransactions', e.sortDesc)
    },
    amountFilterChanged() {
      //await this.getDataList()
      this.dataList = [...this.fullDataList]
      var removeIndexes = []
      this.dataList.forEach((item, index) => {
        if (
          (this.fvalues[0] === 0 && !item.post_meta['amount']) ||
          (parseFloat(item.post_meta['amount']) >= this.fvalues[0] &&
            parseFloat(item.post_meta['amount']) <= this.fvalues[1])
        ) {
          //pass
        } else {
          //remove
          removeIndexes.push(index)
        }
      })
      this.dataList = this.dataList.filter(function (value, index) {
        return removeIndexes.indexOf(index) == -1
      })
      console.log('amountFilterChanged', this.dataList.length)
      this.onFiltered(this.dataList)
    },

    dtFilterChanged() {
      //await this.getDataList()

      console.log('dtFilterChanged 1', this.filterDate)
      this.dataList = [...this.fullDataList]

      if (this.filterDate) {

        var removeIndexes = []
        this.dataList.forEach((item, index) => {

          console.log('dtFilterChanged 2', item.post_date, moment(this.filterDate).format("YYYY-MM-DD"))


          if (
            (item.post_date.includes(moment(this.filterDate).format("YYYY-MM-DD")))
          ) {
            //pass
          } else {
            //remove
            removeIndexes.push(index)
          }
        })
        this.dataList = this.dataList.filter(function (value, index) {
          return removeIndexes.indexOf(index) == -1
        })
        console.log('dtFilterChanged 3', this.dataList.length)
        this.onFiltered(this.dataList)
      }


    },

    resetFilter() {
      this.filter = ''
      this.filterDate = ''
      this.fvalues = [0, this.max]

      document.getElementById("filter-input").value = '';

      this.dataList = [...this.fullDataList]

      this.calcTotals(this.dataList)
    },


    async saveData() {
      if (this.newDescription && this.newKind && this.newAmount) {
        this.loader = this.$loading.show()
        this.loaded = false

        var lastBalance = 0;


        var lastRecord = await getLastBalance();


        console.log('lastRecord', lastRecord);

        if (lastRecord.length > 0) {
          lastBalance = lastRecord[0].post_meta['balance'] ? parseFloat(lastRecord[0].post_meta['balance']) : 0
        }

        var newBalance = this.newKind === 'in' ? parseFloat(lastBalance) + parseFloat(this.newAmount) : parseFloat(lastBalance) - parseFloat(this.newAmount);

        var postData = {
          post_title: moment().format("YYYY-MM-DD") + " " + this.newDescription,
          post_status: 'published'
        }


        var metaData = {
          description: this.newDescription,
          reference: this.newReference,
          kind: this.newKind,
          amount: this.newAmount,
          method: this.newMethod,
          balance: newBalance
        }


        var res = await createTransaction(postData, metaData, false);
        if (res > 0) {

          this.submittedID = res;

          this.$toasted.show(' Inserido com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 });
          //this.$router.push({ path: `/transactions/${res}` })


          this.loaded = false
          this.getDataList()


          this.newAmount = ''
          this.newDescription = ''
          this.newKind = ''
          this.newMethod = ''
          this.newReference = ''


        }
        console.log('saveData-res', res)

      } else {
        this.$toasted.show(' Por favor, defina preencha os campos obrigatórios ', { theme: 'outline', position: 'top-right', type: 'danger', icon: 'info', duration: 5000 });
      }
    },


    calcTotals(data) {

      this.incomes = 0
      this.expenses = 0
      this.balance = 0

      data.forEach((item) => {

        if (item.post_meta['kind'] === "in") {

          this.incomes = parseFloat(this.incomes) + parseFloat(item.post_meta['amount'])

        }
        else if (item.post_meta['kind'] === "out") {

          this.expenses = parseFloat(this.expenses) + parseFloat(item.post_meta['amount'])

        }


      });

      this.balance = this.incomes - this.expenses;

    }
  },
}
</script>